import React from "react"
import { ThemeProvider } from './src/Context/WebContext'
import CookieProvider from "./src/Context/cookies"
import 'aos/dist/aos.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import AOS from 'aos'
AOS.init()

export const wrapRootElement = ({ element }) => (
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.GATSBY_CAPTCHA_KEY}`}>
            <CookieProvider>
                  <ThemeProvider>
                        {element}
                  </ThemeProvider>
            </CookieProvider>
      </GoogleReCaptchaProvider>
)