import React from "react";
import { TypographyStyles } from "../../styles/typography";
import { useViewport } from "../../hooks/useViewport";

interface TypographyProps {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "span"
    | "lead"
    | "small";
  color?: string;
  className?: string;
  children: React.ReactNode;
  sx?: React.CSSProperties;
  link?: boolean;
}

export const Typography = (props: TypographyProps) => {
  const fontBase: React.CSSProperties = {
    // fontFamily: '"neuzeit-grotesk", sans-serif',
    fontFamily: '"Inter", sans-serif',
    color: props.color,
    marginBottom: 5,
    ...props.sx,
  };

  let fontSize = 16;
  fontSize = TypographyStyles[props.variant].fontSize as number;

  const fontSizeRem = `${fontSize / 16}rem`;

  const { width } = useViewport();
  const isMobile = width < 768;

  const mobileFontSize = 0.8 * fontSize;
  const mobileFontSizeRem = `${mobileFontSize / 16}rem`;

  switch (props.variant) {
    case "h1":
      return (
        <h1
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: isMobile ? mobileFontSizeRem : fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </h1>
      );
    case "h2":
      return (
        <h2
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: isMobile ? mobileFontSizeRem : fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </h2>
      );
    case "h3":
      return (
        <h3
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </h3>
      );
    case "h4":
      return (
        <h4
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </h4>
      );
    case "h5":
      return (
        <h5
          style={{ ...fontBase, ...TypographyStyles[props.variant] }}
          className={props.className}
        >
          {props.children}
        </h5>
      );
    case "h6":
      return (
        <h6
          style={{ ...fontBase, ...TypographyStyles[props.variant] }}
          className={props.className}
        >
          {props.children}
        </h6>
      );
    case "p":
      return (
        <p
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </p>
      );
    case "span":
      return (
        <span
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </span>
      );

    case "lead":
      return (
        <p
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </p>
      );

    case "small":
      return (
        <small
          style={{
            ...fontBase,
            ...TypographyStyles[props.variant],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </small>
      );

    default:
      return (
        <p
          style={{
            ...fontBase,
            ...TypographyStyles["p"],
            fontSize: fontSizeRem,
          }}
          className={props.className}
        >
          {props.children}
        </p>
      );
  }
};
