import React from "react";

interface ITypographyStyles {
  h1: React.CSSProperties;
  h2: React.CSSProperties;
  h3: React.CSSProperties;
  h4: React.CSSProperties;
  h5: React.CSSProperties;
  h6: React.CSSProperties;
  p: React.CSSProperties;
  a: React.CSSProperties;
  small: React.CSSProperties;
  strong: React.CSSProperties;
  span: React.CSSProperties;
  lead: React.CSSProperties;
}

export const TypographyStyles: ITypographyStyles = {
  h1: {
    fontWeight: 500,
    fontSize: 52,
  },
  h2: {
    fontWeight: 500,
    fontSize: 40,
  },
  h3: {
    fontWeight: 500,
    fontSize: 30,
  },
  h4: {
    fontWeight: 600,
  },
  h5: {
    fontWeight: 500,
    fontSize: 22,
  },
  h6: {
    fontSize: 18,
    fontWeight: 500,
  },
  p: {
    fontFamily: '"montserrat", sans-serif',
    fontSize: 16,
  },
  lead: {
    fontFamily: '"montserrat", sans-serif',
    fontSize: 18,
  },
  a: {},
  small: {},
  strong: {},
  span: {},
};
