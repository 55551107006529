import React from 'react';
const isBrowser = typeof window !== "undefined"

let defaultWidth


if (typeof window !== `undefined`) {
  defaultWidth = window.innerWidth
}

export const useViewport = () => {

    const [width, setWidth] = React.useState(defaultWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    // Return the width so we can use it in our components
    return { width };

  }


