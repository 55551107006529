import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { Typography } from "../components/Typography";

const defaultValues = {
  accepted: false,
};

const CookieContext = createContext(defaultValues);

const CookieProvider = ({ children }) => {
  const [accepted, setAccepted] = useState(false);
  const [consentOpen, setConsentOpen] = useState(false);

  const value = {
    accepted,
  };

  useEffect(() => {
    if (localStorage.getItem("consent")) {
      setAccepted(true);
    } else {
      setConsentOpen(true);
    }
  }, []);

  useEffect(() => {
    if (accepted) {
      localStorage.setItem("consent", new Date().toDateString());
      setConsentOpen(false);
    }
  }, [accepted]);

  const accept = () => {
    setAccepted(true);
  };

  return (
    <CookieContext.Provider value={value}>
      <ConsentForm setAccepted={accept} open={consentOpen} />
      {children}
    </CookieContext.Provider>
  );
};

const ConsentForm = ({ setAccepted, open }) => {
  const accept = () => {
    setAccepted(true);
  };

  const reject = () => {};

  //     return <Modal show={open} centered>
  //     <Modal.Header>
  //       <Modal.Title>We use cookies</Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
  //     <Modal.Footer>
  //       <Button variant="secondary" onClick={() => accept()}>
  //         Accept Cookies
  //       </Button>
  //     </Modal.Footer>
  //   </Modal>

  return (
    <div
      style={{
        display: open ? "block" : "none",
        position: "fixed",
        width: "100%",
        height: "auto",
        bottom: 0,
        backgroundColor: "#95A5A6",
        zIndex: 100,
        padding: 20,
        paddingBottom: 20,
        color: "white",
      }}
    >
      <Typography variant="h2">Your Privacy</Typography>
      <Typography variant="p">
        We use cookies to improve your experience on our site. To find out more,
        read our <a>privacy policy</a>.
      </Typography>
      <Button
        variant="secondary"
        onClick={() => {
          accept();
        }}
      >
        Accept Cookies!
      </Button>
    </div>
  );
};

export default CookieProvider;
